<template>
    <li class="flex items-start gap-2 border-b p-3 sm:items-center">
        <div class="flex flex-col self-start">
            <div class="hidden pl-4 md:block">
                <prime-avatar
                    :icon="icon"
                    size="large"
                    shape="circle"
                    class="!bg-gray-200 text-gray-800"
                />
            </div>
            <div class="pl-4 sm:pr-1 md:hidden md:pr-0">
                <prime-avatar
                    :icon="icon"
                    size="normal"
                    shape="circle"
                    class="!bg-gray-200 text-gray-800"
                />
            </div>
        </div>

        <div class="flex flex-1 flex-col lg:mr-16">
            <div
                class="flex flex-col items-start gap-1 lg:flex-row lg:items-center lg:gap-4"
            >
                <div class="text-xl font-medium text-gray-900">
                    {{ lineItemData.description }}
                </div>
                <template v-if="membershipLink !== null">
                    <div class="hidden text-gray-500 lg:block">&mdash;</div>
                    <nuxt-link :to="membershipLink.link">
                        <prime-button
                            link
                            :label="membershipLink.label"
                            class="!p-0"
                        />
                    </nuxt-link>
                </template>
            </div>
            <div
                v-for="subDescription in lineItemData.subDescription"
                class="text-sm text-gray-600"
            >
                {{ subDescription }}
            </div>

            <div class="mt-2">
                {{ lineItemData.quantity }}&nbsp;&nbsp;<span
                    class="font-medium text-gray-400"
                    >x</span
                >&nbsp;&nbsp;{{ formatCurrency(lineItemData.unitPrice) }}
            </div>
        </div>

        <div class="text-lg font-medium text-gray-900">
            {{ formatCurrency(lineItemData.extendedPrice) }}
        </div>
    </li>
</template>

<script lang="ts">
import { graphql } from '~/resources/graphql'

export const InvoiceLineItemDisplayInvoiceItemFragment = graphql(/* GraphQL */ `
    fragment InvoiceLineItemDisplayInvoiceItemFragment on InvoiceItem {
        quantity
        description
        subDescription
        unitPrice
        extendedPrice
        contract {
            __typename
            ... on PlanContract {
                id
                planType
            }
        }
    }
`)
</script>

<script setup lang="ts">
import { formatCurrency } from '~/composables/support/localization'
import type { FragmentType } from '~/resources/graphql'
import { getFragmentData } from '~/resources/graphql'
import PrimeButton from 'primevue/button'
import { useRouteLink } from '~/composables/routing'
import nuxtLink from '#app/components/nuxt-link'
import { computed } from 'vue'
import PrimeAvatar from 'primevue/avatar'
import { useLayoutMode } from '~/composables/use-layout-mode'

const props = defineProps<{
    lineItem: FragmentType<typeof InvoiceLineItemDisplayInvoiceItemFragment>
    firstForContract: boolean
}>()

const lineItemData = getFragmentData(
    InvoiceLineItemDisplayInvoiceItemFragment,
    props.lineItem
)

const { isKiosk } = useLayoutMode()
const { profileBookingLink, profileMembershipLink, profilePassLink } =
    useRouteLink()
const membershipLink = computed(() => {
    if (
        !isKiosk.value &&
        props.firstForContract &&
        lineItemData.contract !== null &&
        lineItemData.contract.__typename === 'PlanContract'
    ) {
        if (lineItemData.contract.planType === 'MEMBERSHIP') {
            return {
                label: 'View Membership',
                link: profileMembershipLink(lineItemData.contract.id)
            }
        } else if (lineItemData.contract.planType === 'PROGRAM') {
            return {
                label: 'View Booking',
                link: profileBookingLink(lineItemData.contract.id)
            }
        } else if (lineItemData.contract.planType === 'PASS') {
            return {
                label: 'View Pass',
                link: profilePassLink(lineItemData.contract.id)
            }
        }
    }
    return null
})

const icon = computed(() => {
    if (
        lineItemData.contract !== null &&
        lineItemData.contract.__typename === 'PlanContract'
    ) {
        if (lineItemData.contract.planType === 'PASS') {
            return 'pi pi-ticket'
        }

        if (lineItemData.contract.planType === 'PROGRAM') {
            return 'pi pi-calendar'
        }

        return 'pi pi-users'
    }

    return 'pi pi-shopping-bag'
})
</script>
