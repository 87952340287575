<template>
    <div
        class="mx-6 mb-12 max-w-screen-xl space-y-5 lg:mx-auto lg:my-12 lg:min-w-[1024px]"
    >
        <div>
            <span class="text-xl text-gray-700">Thanks!</span>
            <div class="my-2 text-4xl font-bold text-gray-900">
                Successful Order
            </div>
            <p
                v-if="!isKiosk"
                class="mb-4 mt-0 p-0 text-xl text-gray-700"
            >
                We look forward to seeing you soon!
            </p>
            <div
                :style="{
                    height: '3px',
                    background:
                        'linear-gradient(90deg, rgb(var(--color-primary-600)) 0%, rgba(33, 150, 243, 0) 50%)'
                }"
            ></div>
        </div>

        <div
            class="flex flex-col sm:flex-row sm:items-center sm:justify-between"
        >
            <div class="mb-3 sm:mb-0">
                <span class="mr-2 text-xl font-medium text-gray-900"
                    >Order number:</span
                >
                <nuxt-link
                    v-if="!isKiosk"
                    :to="profileInvoiceLink(invoice.id)"
                >
                    <span class="text-xl font-medium text-primary-500"
                        >#{{ invoice.referenceNumber }}</span
                    >
                </nuxt-link>
                <span
                    v-else
                    class="text-xl font-medium text-primary-500"
                    >#{{ invoice.referenceNumber }}</span
                >
            </div>
        </div>

        <ul class="m-0 list-none p-0">
            <invoice-line-item-display
                v-for="(lineItem, i) in invoice.lineItems"
                :key="i"
                :class="{
                    'border-b-0': i === invoice.lineItems.length - 1
                }"
                :line-item="lineItem"
                :first-for-contract="
                    firstContractInvoiceItemIds.has(lineItem.id)
                "
            />
        </ul>

        <div class="flex flex-wrap">
            <div
                v-if="!isKiosk"
                class="w-full pl-3 lg:w-1/2"
            >
                <span class="text-xl font-medium text-gray-900"
                    >Billing Address</span
                >
                <div class="mb-5 mt-1 flex flex-col text-gray-900">
                    <span>{{ checkoutData.customer.commaName }}</span>
                    <template v-if="checkoutData.customer.address1 !== ''">
                        <span>{{ checkoutData.customer.address1 }}</span>
                        <span v-if="checkoutData.customer.address2 !== ''">
                            {{ checkoutData.customer.address2 }}
                        </span>
                        <div>
                            {{ checkoutData.customer.city }},
                            {{ checkoutData.customer.state }}
                            {{ checkoutData.customer.postal }}
                        </div>
                    </template>
                    <template v-else>
                        <div>
                            {{ checkoutData.customer.postal }}
                            {{ checkoutData.customer.country }}
                        </div>
                    </template>
                </div>

                <span class="text-lg font-medium text-gray-900">Payment</span>
                <div class="mt-3 flex items-center">
                    <div class="flex-shrink-0 pr-4">
                        <i
                            class="pi pi-credit-card rounded-full bg-gray-300 p-4 text-4xl"
                        ></i>
                    </div>
                    <div class="flex flex-col">
                        <div v-for="payment in invoice.friendlyBindings">
                            <span
                                v-if="payment.isSubItem"
                                class="text-sm text-gray-700"
                            >
                                {{ payment.label }} {{ payment.amount }}
                            </span>
                            <span v-else>
                                {{ payment.label }} {{ payment.amount }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="mt-5 flex w-full items-end pl-3 lg:mt-0 lg:w-1/2 lg:pl-0 lg:pr-3"
            >
                <ul class="m-0 w-full list-none space-y-3 p-0">
                    <li>
                        <span class="font-medium text-gray-900">Summary</span>
                    </li>
                    <li class="flex justify-between border-t pt-4">
                        <span class="text-gray-900">Subtotal</span>
                        <span class="text-lg font-medium text-gray-900">{{
                            formatCurrency(invoice.subTotal)
                        }}</span>
                    </li>
                    <li
                        v-if="inclusiveSalesTax.isNotZero()"
                        class="flex justify-between"
                    >
                        <span class="text-gray-900">Inclusive Tax</span>
                        <span class="text-lg font-medium text-gray-900">{{
                            inclusiveSalesTax.formatCurrency()
                        }}</span>
                    </li>
                    <li class="flex justify-between">
                        <span class="text-gray-900">Tax</span>
                        <span class="text-lg font-medium text-gray-900">{{
                            formatCurrency(invoice.salesTaxExclusive)
                        }}</span>
                    </li>
                    <li class="flex justify-between border-t py-3">
                        <span class="font-medium text-gray-900">Total</span>
                        <span class="text-lg font-bold text-gray-900">{{
                            formatCurrency(invoice.total)
                        }}</span>
                    </li>
                </ul>
            </div>
        </div>

        <div
            v-if="!isKiosk"
            class="flex justify-end"
        >
            <nuxt-link :to="facilityHome">
                <prime-button
                    label="Return to Homepage"
                    icon="pi pi-fw pi-arrow-left"
                    severity="secondary"
                />
            </nuxt-link>
        </div>
    </div>
</template>

<script lang="ts">
import { graphql } from '~/resources/graphql'

export const CheckoutSuccessFragment = graphql(/* GraphQL */ `
    fragment CheckoutSuccessFragment on CommitCheckoutResult {
        customer {
            id
            commaName
            firstName
            address1
            address2
            city
            state
            postal
            country
        }
        invoice {
            id
            referenceNumber
            lineItems {
                id
                contract {
                    __typename
                    ... on PlanContract {
                        id
                    }
                }
                ...InvoiceLineItemDisplayInvoiceItemFragment
            }
            total
            subTotal
            salesTaxExclusive
            salesTaxInclusive
            friendlyBindings {
                label
                amount
                isSubItem
            }
        }
    }
`)
</script>

<script setup lang="ts">
import { useHead } from 'unhead'
import PrimeButton from 'primevue/button'
import nuxtLink from '#app/components/nuxt-link'
import { computed } from 'vue'
import type { FragmentType } from '~/resources/graphql'
import { getFragmentData } from '~/resources/graphql'
import { formatCurrency } from '~/composables/support/localization'
import InvoiceLineItemDisplay from '~/components/Checkout/InvoiceLineItemDisplay.vue'
import { Decimal } from '~/composables/support/decimal'
import { useRouteLink } from '~/composables/routing'
import { useActiveFacility } from '~/composables/use-active-facility'
import { useLayoutMode } from '~/composables/use-layout-mode'

const props = defineProps<{
    checkoutResultData: FragmentType<typeof CheckoutSuccessFragment>
}>()

const checkoutData = getFragmentData(
    CheckoutSuccessFragment,
    props.checkoutResultData
)

useHead({
    title: 'Success'
})

const { isKiosk } = useLayoutMode()
const activeFacility = useActiveFacility()
const { facilityHomeLink, profileInvoiceLink } = useRouteLink()
const facilityHome = computed(() => facilityHomeLink(activeFacility.value.slug))

const invoice = computed(() => {
    if (checkoutData.invoice === null) {
        throw new Error('Error null invoice on checkout.')
    } else {
        return checkoutData.invoice
    }
})

const firstContractInvoiceItemIds = computed(() => {
    const invoiceItemIds = new Set()
    const usedContractIds = new Set()
    invoice.value.lineItems.forEach(item => {
        if (
            item.contract !== null &&
            item.contract.__typename === 'PlanContract' &&
            !usedContractIds.has(item.contract.id)
        ) {
            usedContractIds.add(item.contract.id)
            invoiceItemIds.add(item.id)
        }
    })
    return invoiceItemIds
})

const inclusiveSalesTax = computed(
    () => new Decimal(invoice.value.salesTaxInclusive)
)
</script>
